import offer21 from './images/Wariant21.jpeg';
import offer22 from './images/Wariant22.jpeg';


export const dataDigitalBestSeller = [
  {
    id: 1,
    title: 'slide 1',
    linkImg:
    offer21,
      
  },
  {
    id: 2,
    title: 'slide 2™',
    linkImg:
    offer22,
  },
];