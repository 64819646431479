import offer31 from './images/Wariant31.jpeg';
import offer32 from './images/Wariant32.jpeg';
import offer33 from './images/Wariant33.jpeg';


export const dataDigitalBestSeller = [
  {
    id: 1,
    title: 'slide 1',
    linkImg:
    offer31,
      
  },
  {
    id: 2,
    title: 'slide 2™',
    linkImg:
    offer32,
  },
  {
    id: 3,
    title: 'slide 2™',
    linkImg:
    offer33,
  },

];