import offer1 from './images/Wariant1.jpeg';


export const dataDigitalBestSeller = [
    {
      id: 1,
      title: 'slide 1',
      linkImg:
      offer1,
    },
  ];